import Model, { hasMany, belongsTo, attr } from '@ember-data/model';
import { dasherize } from '@ember/string';
import { importSync } from '@embroider/macros';
import { ensureSafeComponent } from '@embroider/util';
import { inject as service } from '@ember/service';

export const dismissalFlagsByCardType = {
  add_points_program_type: 'hasDismissedAddProgramTypePointsCard',
  add_referrals_program_type: 'hasDismissedAddProgramTypeReferralsCard',
  referral_share_link: 'hasDismissedSendReferralLinksCard',
  show_nudges: 'dismissedNudgesCard',
};

const types = {
  // This exists on the API, but missing on the frontend, so it's never shown
  // TODO remove completely or add back
  // ad_spend_saved: 'ad-spend-saved',
  addPointsProgramType: 'add_points_program_type',
  addReferralsProgramType: 'add_referrals_program_type',
  appError: 'app_error',
  bigcommerceConnection: 'bigcommerce_connection',
  htmlEmailsDeprecationInfo: 'html_emails_deprecation_info',
  importCodesPointsProducts: 'import_codes_points_products',
  importCodesReferralRewards: 'import_codes_referral_rewards',
  importCodesVipTierRewards: 'import_codes_vip_tier_rewards',
  // This exists on the API, but missing on the frontend, so it's never shown
  // TODO remove completely or add back
  // leave_a_review_bigcommerce: 'leave-a-review-bigcommerce',
  // This exists on the API but is disabled, so it's never shown
  // TODO remove completely or add back
  // new_feature_release: 'new-feature-release',
  paymentUpdateRequired: 'payment_update_required',
  promoteAccountCreationNudge: 'promote_account_creation_nudge',
  promoteCustomizedBannerImageFeature:
    'promote_customized_banner_image_feature',
  promoteEspAppUsage: 'promote_esp_app_usage',
  promoteGuestEarning: 'promote_guest_earning',
  promoteIncreaseReferralsNudge: 'promote_increase_referrals_nudge',
  promoteIntegrations: 'promote_integrations',
  promotePointsExpiryFeature: 'promote_points_expiry_feature',
  promoteReferralRewardExpiryFeature: 'promote_referral_reward_expiry_feature',
  // This exists on the API but is disabled, so it's never shown
  // TODO remove completely or add back
  // referral_share_link: 'referral-share-link',
  setupPointsProducts: 'setup_points_products',
  shopifyConnection: 'shopify_connection',
  smileUiMisconfiguration: 'smile_ui_misconfiguration',
  // This exists on the API, but missing on the frontend, so it's never shown
  // TODO remove completely or add back
  viewBlogPost: 'view_blog_post',
  visitKnowledgeBase: 'visit_knowledge_base',
};

export default Model.extend({
  shopify: service(),

  type: attr('string'),
  category: attr('string'),

  newIntegration: belongsTo('new-integration', { async: false, inverse: null }),
  unhealthyIntegrations: hasMany('new-integration', {
    async: false,
    inverse: null,
  }),
  featureRelease: belongsTo('feature-release', { async: false, inverse: null }),
  nudge: belongsTo('nudge', { async: false, inverse: null }),
  activityRule: belongsTo('activity-rule', { async: false, inverse: null }),

  get component() {
    if (!Object.values(types).includes(this.type)) {
      return null;
    }

    let componentModule = importSync(
      `../components/home-card/${dasherize(this.type)}`
    );
    return ensureSafeComponent(componentModule.default, this);
  },

  get shouldRender() {
    if (
      this.type.includes(
        types.promoteIntegrations,
        types.promotePointsExpiryFeature,
        types.addPointsProgramType
      )
    ) {
      return !this.shopify.isEmbedded;
    }

    return true;
  },
});
