import Model, { attr } from '@ember-data/model';
import { equal } from '@ember/object/computed';

export const types = {
  bigcommerce: 'bigcommerce_connected_account',
  bronto: 'bronto_connected_account',
  dotmailer: 'dotmailer_connected_account',
  hubspot: 'hubspot_connected_account',
  klaviyo: 'klaviyo_connected_account',
  mailchimp: 'mailchimp_connected_account',
  recharge: 'recharge_connected_account',
  shopify: 'shopify_connected_account',
  yotpo: 'yotpo_connected_account',
};

export default Model.extend({
  type: attr('string'),
  oauthUserName: attr('string'),
  connectionStatus: attr('string'),
  uid: attr('string'),
  oauthScopes: attr('array'),
  missingWritePermissions: attr('array'),

  api_key: attr('string'),
  api_secret: attr('string'),

  isConnectionHealthy: equal('connectionStatus', 'healthy'),
  isConnectionUnhealthy: equal('connectionStatus', 'unhealthy'),
});
