import { macroCondition, importSync, getOwnConfig } from '@embroider/macros';

export function setupAppPerformanceTracking() {
  if (macroCondition(getOwnConfig().measureWebVitals)) {
    const { onLCP, onINP, onCLS } = importSync('web-vitals/attribution');

    const logWebVitals = (metric) => {
      console.log(metric.name, metric);
    };

    onCLS(logWebVitals, { reportAllChanges: true });
    onINP(logWebVitals, { reportAllChanges: true });
    onLCP(logWebVitals, { reportAllChanges: true });
  }

  if (macroCondition(getOwnConfig().measureRawLayoutShiftsEnabled)) {
    const observer = new PerformanceObserver((list) => {
      for (const entry of list.getEntries()) {
        if (entry.hadRecentInput) {
          return;
        }
        // if (entry.value < 0.01) return; // Ignore negligible CLS
        console.log('LayoutShift:', entry);
      }
    });

    observer.observe({ type: 'layout-shift', buffered: true });
  }
}
