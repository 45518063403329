import { run } from '@ember/runloop';

const loadedScripts = {};

/**
 * Loads a script into a document, ensuring it's loaded only once when loading in the main browser
 * document.
 *
 * @param {string} url        The script to load
 * @param {object} opts       Options for loading the script
 * @param {HTMLDocument} opts.doc Optional. If provided, loads the script in the given document (e.g., iframe).
 *                                When present, the script is not cached and will reload every time.
 * @param {boolean} opts.async Optional. Whether to load the script asynchronously (default: true).
 * @returns {Promise<void>}   Resolves when the script is successfully loaded.
 */
export async function loadScript(url, { doc = document, async = true } = {}) {
  doc = doc !== null ? doc : document;
  const isLoadingInMainDoc = doc === document;

  // Return cached promise if already loading in the main document
  if (isLoadingInMainDoc && loadedScripts[url]) {
    return loadedScripts[url];
  }

  // Create a new promise for loading the script
  const promise = new Promise((resolve, reject) => {
    const element = doc.createElement('script');
    element.type = 'text/javascript';
    element.async = async;

    element.addEventListener('load', () => run(resolve), false);
    element.addEventListener(
      'error',
      () => {
        const error = new Error(`Could not load script: ${url}`);
        run(() => reject(error));
      },
      false
    );

    element.src = url;

    const head = doc.head || doc.getElementsByTagName('head')[0];
    head?.appendChild(element); // Use optional chaining for safety
  });

  // Cache the promise if loading in the main document
  if (isLoadingInMainDoc) {
    loadedScripts[url] = promise;
  }

  return promise;
}
