import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { reads } from '@ember/object/computed';
import { attemptShopifyOauth } from 'smile-admin/services/shopify';

export default class ApplicationRoute extends Route {
  @service('router') router;
  @service errorHandler;
  @service intl;
  @service session;
  @service shopify;
  @service utils;
  @service sesh;

  @reads('sesh.account')
  account;

  async beforeModel(transition) {
    super.beforeModel(...arguments);

    await this.session.setup();
    this.intl.setLocale('en-US');

    return attemptShopifyOauth(transition);
  }

  /**
   * Application wide action used by the customer-search component.
   * We have the action here so the component can be placed on any
   * page of the application and still function
   */
  @action
  showCustomer(customer) {
    this.router.transitionTo('customers.show', customer.id);
  }

  @action
  goHome() {
    this.router.transitionTo('home');
  }

  @action
  goLogin() {
    this.router.transitionTo('auth.login');
  }

  @action
  invalidateSession() {
    this.session.invalidate();
  }

  @action
  enableTopBar() {
    // When embedded in Shopify, we never use the top-bar
    if (this.shopify.isEmbedded) {
      return;
    }

    this.set('controller.isTopBarVisible', true);
  }

  @action
  disableTopBar() {
    // When embedded in Shopify, we never use the top-bar
    if (this.shopify.isEmbedded) {
      return;
    }

    this.set('controller.isTopBarVisible', false);
  }

  // Reload the current page
  @action
  reloadPage() {
    window.location.reload();
  }

  @action
  error(error) {
    return this.errorHandler.handleRouteError(error);
  }
}
