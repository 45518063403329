import Service, { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { openExternalLink } from '@smile-io/ember-smile-core/utils/external-links';

export default class UtilsService extends Service {
  @service shopify;
  @service config;
  @service sesh;
  @service router;

  /**
   * Utility to open an external link.
   * Uses Shopify App Bridge utilities when embedded inside Shopify, falling back on
   * `openExternalLink` provided by `ember-smile-core` otherwise.
   *
   * @param {String} link                 The link to open.
   * @param {Object} options              Options for opening the link.
   * @property {Boolean} options.newTab   When true (default), opens the link in a new tab.
   * @property {Boolean} options.replace  When true (default), replaces the current URL (skipping history).
   *                                      Set to false, if you want to preserve history. Only applies
   *                                      when `newTab` is `false`
   */
  @action
  openExternalLink(link, { newTab = true } = {}) {
    // If it's Shopify embedded
    // Docs https://shopify.dev/docs/api/app-bridge-library/apis/navigation#example-navigating-to-external-url's
    if (this.shopify.isEmbedded) {
      // Convert relative paths to absolute URLs when openning in a new tab
      if (newTab === true && link.startsWith('/')) {
        link = `shopify://admin/apps/${this.config.get('shopify.clientId')}${link}`;
      }

      open(link, newTab ? '_blank' : '_top');
      return;
    }

    openExternalLink(...arguments);
  }
}
