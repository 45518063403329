import config from '../config/environment';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

export function initialize(application) {
  const sharedConfig = {
    clientToken: config.datadog.clientToken,
    site: 'datadoghq.com',
    service: config.modulePrefix,
    env: config.smileEnv,
    version: application.version.replace('+', '_'),
  };

  if (config.datadog.enabled) {
    datadogRum.init({
      ...sharedConfig,
      applicationId: config.datadog.applicationId,

      // Track basic sessions, views, actions, and errors for all sessions.
      sessionSampleRate: 100,

      // Track views manually, so we can track them in the router service.
      // This approach resolves an issue with Smile when embedded in Shopify.
      // For more details, see: https://smileio.atlassian.net/browse/AS-675
      trackViewsManually: true,

      enableExperimentalFeatures: ['feature_flags'],

      allowedTracingUrls: [config.apiHost],

      // Use a secure cross-site session cookie. Required for embedded Smile (when loaded in an iFrame)
      useSecureSessionCookie: true,
      usePartitionedCrossSiteSessionCookie: true,

      beforeSend: (event /* , context */) => {
        if (
          event.type === 'error' &&
          event.error.message.includes('ResizeObserver loop limit exceeded')
        ) {
          return false;
        }
      },
    });

    datadogLogs.init(sharedConfig);
    // Send logs to console too if non-production
    datadogLogs.logger.setHandler(
      ['http', config.smileEnv !== 'production' ? 'console' : null].filter(
        Boolean
      )
    );
  }
}

export default {
  initialize,
};
