/* import __COLOCATED_TEMPLATE__ from './upgrade.hbs'; */
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { equal, readOnly } from '@ember/object/computed';
import WidePolarisModal from '@smile-io/ember-smile-core/components/polaris-modals/wide-modal';

export default class Upgrade extends WidePolarisModal {
  @service
  billing;

  @service
  metrics;

  @service
  router;

  @service
  sesh;

  @readOnly('modalParams.isLoading')
  isLoading;

  @readOnly('modalParams.subscriptionTemplate')
  subscriptionTemplate;

  @readOnly('modalParams.yearlySubscriptionTemplate')
  yearlySubscriptionTemplate;

  @computed(
    'sesh.account.chargeableSubscription.basePlan.{isMonthly,isYearly}',
    'billing.supportsAnnualSubscriptions'
  )
  get showYearlyPricing() {
    let {
      basePlan: { isYearly },
    } = this.sesh.account.chargeableSubscription;

    return isYearly && this.billing.supportsAnnualSubscriptions;
  }

  // TODO: remove this when we no longer display the upgrade modal on the billing page.
  @(equal('router.currentRouteName', 'settings.billing.index').readOnly())
  hideViewMorePlansLink;

  init() {
    super.init(...arguments);

    this.metrics.trackEvent('GoogleTagManager', {
      event: 'Billing - upgrade modal viewed',
    });
  }

  @action
  trackAndCloseModal() {
    if (this.confirm.isRunning) {
      return;
    }

    this.billing.trackEvent(
      `Billing - ${this.subscriptionTemplate.name} modal dismissed`
    );
    this.send('closeModal');
  }

  @action
  contactSales() {
    const { name } = this.subscriptionTemplate;
    this.billing.trackEvent(`Billing - ${name} modal contact button clicked`);
    this.billing.contactSales(`I’m looking for more info on the ${name} plan.`);
  }
}
