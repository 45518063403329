import Model, { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { equal, match } from '@ember/object/computed';
import { slugs } from 'smile-admin/models/billing-subscription-template';

const planType = 'plan';
const membersAddOnType = 'members_add_on';
const ordersAddOnType = 'orders_add_on';
const appsAddOnType = 'apps_add_on';

const enterpriseSlugRegex = new RegExp(slugs.enterprise, 'ig');

export default Model.extend({
  type: attr('string'),
  name: attr('string'),
  slug: attr('string'),

  createdAt: attr('date'),
  updatedAt: attr('date'),

  isPlanType: equal('type', planType).readOnly(),
  isMembersAddOnType: equal('type', membersAddOnType).readOnly(),
  isOrdersAddOnType: equal('type', ordersAddOnType).readOnly(),
  isAppsAddOnType: equal('type', appsAddOnType).readOnly(),

  isFree: equal('slug', slugs.free).readOnly(),
  isEnterprise: match('slug', enterpriseSlugRegex).readOnly(),

  isStarter: equal('slug', slugs.starter).readOnly(),
  isGrowth: equal('slug', slugs.growth).readOnly(),
  isPro: equal('slug', slugs.pro).readOnly(),

  isStarterWithOrderLimit: equal(
    'slug',
    slugs.starterWithOrderLimit
  ).readOnly(),
  isGrowthWithOrderLimit: equal('slug', slugs.growthWithOrderLimit).readOnly(),
  isPlus: equal('slug', slugs.plus).readOnly(),

  isLegacyGrowth: computed('slug', function () {
    if (this.get('slug') === 'legacy_custom_growth') {
      return true;
    }

    return this.get('slug') === slugs.legacyGrowth;
  }).readOnly(),

  isLegacy: computed('isPlanType', 'slug', function () {
    if (this.get('slug') === 'legacy_custom_growth') {
      return false;
    }

    return this.isPlanType && this.slug.indexOf('legacy') !== -1;
  }).readOnly(),

  displayName: computed('name', function () {
    return this.get('name').replace(' Plan', '');
  }).readOnly(),
});
