import Model, { belongsTo, attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { equal, gt, alias } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import { memberAction } from 'ember-api-actions';
import { serializeAndPush } from 'smile-admin/utils/models/serialize-and-push';
import { inject as service } from '@ember/service';

export const types = {
  bigcommerceFreeProduct: 'bigcommerce_free_product',
  bigcommerceFreeShippingCoupon: 'bigcommerce_free_shipping_coupon',
  bigcommercePerTotalDiscountCoupon: 'bigcommerce_per_total_discount_coupon',
  bigcommercePercentageDiscountCoupon: 'bigcommerce_percentage_discount_coupon',
  bigcommerceStoreCredit: 'bigcommerce_store_credit',

  customImportedCoupon: 'custom_imported_coupon',
  developerCoupon: 'developer_coupon',
  email: 'email',
  points: 'points',

  rechargeSubscriptionFixedAmountDiscount:
    'recharge_subscription_fixed_amount_discount',
  rechargeSubscriptionPercentageDiscount:
    'recharge_subscription_percentage_discount',

  rewardFulfillmentApi: 'reward_fulfillment_api',

  shopifyGiftCard: 'shopify_gift_card',

  shopifyImportedFixedAmountDiscount: 'shopify_imported_fixed_amount_discount',
  shopifyImportedFreeProduct: 'shopify_imported_free_product',
  shopifyImportedPercentageDiscount: 'shopify_imported_percentage_discount',
  shopifyImportedShippingDiscount: 'shopify_imported_shipping_discount',

  shopifyPosFixedAmountDiscount: 'shopify_pos_fixed_amount_discount',
  shopifyPosPercentageDiscount: 'shopify_pos_percentage_discount',

  shopifyPriceRuleFixedAmountDiscount:
    'shopify_price_rule_fixed_amount_discount',
  shopifyPriceRuleFreeProduct: 'shopify_price_rule_free_product',
  shopifyPriceRuleFreeShippingDiscount:
    'shopify_price_rule_free_shipping_discount',
  shopifyPriceRulePercentageDiscount: 'shopify_price_rule_percentage_discount',
};

export default Model.extend({
  sesh: service(),

  name: attr('string'),
  name_template: attr('string'),
  description: attr('string'),
  image_url: attr('string'),
  imageSvg: attr('string'),
  type: attr('string'),
  value: attr('number'),

  supports_consumable_codes: attr('boolean'),
  consumable_codes_available_count: attr('number'),

  fulfillment_name_template: attr('string'),
  fulfillment_instructions_html_template: attr('string'),
  fulfillment_action_text_template: attr('string'),
  fulfillment_action_url_template: attr('string'),

  customized_name_template: attr('string'),
  customized_description_template: attr('string'),
  customized_image_url: attr('string'),
  customized_image_temp_s3_key: attr('string'),
  customized_fulfillment_name_template: attr('string'),
  customized_fulfillment_instructions_html_template: attr('string'),
  customized_fulfillment_action_text_template: attr('string'),
  customized_fulfillment_action_url_template: attr('string'),
  customizedFulfillmentUsageInstructionsTemplate: attr('string'),

  reward_fulfillments_count: attr('number'),

  used_as_referral_reward: attr('boolean'),

  supportsExpiry: attr('boolean'),
  expiryIntervalCount: attr('number'),
  expiryInterval: attr('string'),

  created_at: attr('utc'),
  updated_at: attr('utc'),

  // bigcommerce_coupon, shopify_coupon
  prefix: attr('string'),
  // bigcommerce_coupon
  applies_to_ids: attr('array'),
  /**
   * Attributes specific per reward type
   */
  applies_to_type: attr('string'),
  min_purchase: attr('number'),
  restricted_to: attr(''),
  shipping_methods: attr(''),
  // shopify_coupon
  applies_to_resource: attr('string'),
  applies_to_id: attr('string'),
  applies_once: attr('boolean'),
  applies_once_per_customer: attr('boolean'),
  minimum_order_amount: attr('number'),
  // bigcommerce_free_product, shopify_imported_free_product
  product_name: attr('string'),
  // bigcommerce_free_product, shopify_imported_free_product
  product_link: attr('string'),
  // Price rules properties
  target_type: attr('string'),
  target_selection: attr('string'),
  allocation_method: attr('string'),
  value_type: attr('string'),
  once_per_customer: attr('boolean'),
  customer_selection: attr('string'),
  maximum_shipping_amount: attr('number'),
  entitled_product_ids: attr('array'),
  productId: attr('number'),
  variantId: attr('number'),
  variantName: attr('string'),
  entitled_collection_ids: attr('array'),
  starts_at: attr('utc'),
  // Selected Shopify rewards only, used to save discount combination settings.
  // This is deliberately not defined as an array attribute to avoid Ember Data
  // initialising it to an empty array when the value returned by the back end
  // is null. This gives us traceability in the DB of which rewards have ever
  // had a discount combination set on them.
  combinesWith: attr(''),

  account: belongsTo('account', { async: false, inverse: null }),
  vip_tier: belongsTo('vip-tier', { async: false, inverse: 'rewards' }),
  reward_definition: belongsTo('reward-definition', {
    async: false,
    inverse: null,
  }),
  rewardProgram: belongsTo('reward-program', { async: false, inverse: null }),

  bigcommerceProductId: alias('applies_to_ids.0'),

  entitledProductId: alias('entitled_product_ids.0'),
  entitledCollectionId: alias('entitled_collection_ids.0'),

  hasConsumableCodesInventory: gt('consumable_codes_available_count', 0),

  isShopifyGiftCardType: equal('type', types.shopifyGiftCard),
  isBigcommerceFreeProductType: equal('type', types.bigcommerceFreeProduct),
  isPointsType: equal('type', types.points),

  isShopifyPriceRuleFreeShippingDiscountType: equal(
    'type',
    types.shopifyPriceRuleFreeShippingDiscount
  ),
  isShopifyPriceRuleFreeProductType: equal(
    'type',
    types.shopifyPriceRuleFreeProduct
  ),
  isShopifyType: computed('type', function () {
    return this.type.indexOf('shopify') !== -1;
  }),
  isBigcommerceType: computed('type', function () {
    return this.type.indexOf('bigcommerce') !== -1;
  }),
  isBigcommerceFreeShippingCouponType: equal(
    'type',
    types.bigcommerceFreeShippingCoupon
  ),

  entitled_product_variant_ids: computed('productId', 'variantId', {
    get() {
      let variants = {};
      if (isEmpty(this.productId)) {
        return null;
      }
      if (isEmpty(this.variantId)) {
        variants[this.productId.toString()] = [];
      } else {
        variants[this.productId.toString()] = [this.variantId.toString()];
      }
      return variants;
    },
    set(_key, value) {
      return value;
    },
  }),

  shopifyProductId: computed(
    'applies_to_id',
    'applies_to_resource',
    function () {
      if (this.get('applies_to_resource') === 'product') {
        return this.get('applies_to_id');
      }

      return null;
    }
  ),

  shopifyCollectionId: computed(
    'applies_to_id',
    'applies_to_resource',
    function () {
      if (this.get('applies_to_resource') !== 'product') {
        return this.get('applies_to_id');
      }

      return null;
    }
  ),

  isFreeShippingType: computed('type', function () {
    return [
      types.bigcommerceFreeShippingCoupon,
      types.shopifyPriceRuleFreeShippingDiscount,
      types.shopifyImportedShippingDiscount,
    ].includes(this.type);
  }),

  isFreeProductType: computed('type', function () {
    return [
      types.bigcommerceFreeProduct,
      types.shopifyPriceRuleFreeProduct,
      types.shopifyImportedFreeProduct,
    ].includes(this.type);
  }),

  isShopifyFreeProductType: computed('type', function () {
    return [
      types.shopifyPriceRuleFreeProduct,
      types.shopifyImportedFreeProduct,
    ].includes(this.type);
  }),

  suportsPrefix: computed('type', function () {
    return [
      types.bigcommerceFreeProduct,
      types.bigcommerceFreeShippingCoupon,
      types.bigcommercePerTotalDiscountCoupon,
      types.bigcommercePercentageDiscountCoupon,
      types.shopifyPriceRuleFixedAmountDiscount,
      types.shopifyPriceRuleFreeProduct,
      types.shopifyPriceRuleFreeShippingDiscount,
      types.shopifyPriceRulePercentageDiscount,
    ].includes(this.type);
  }),

  supportsEntitledCollectionIds: computed('type', function () {
    return [
      types.shopifyPriceRuleFixedAmountDiscount,
      types.shopifyPriceRulePercentageDiscount,
    ].includes(this.type);
  }),

  supportsMinimumOrderAmount: computed('type', function () {
    if (this.isShopifyType) {
      return ![
        types.shopifyPosFixedAmountDiscount,
        types.shopifyPosPercentageDiscount,
        types.shopifyPriceRuleFreeShippingDiscount,
        types.shopifyPriceRuleFreeProduct,
        types.shopifyImportedFixedAmountDiscount,
        types.shopifyImportedPercentageDiscount,
        types.shopifyImportedFreeProduct,
        types.shopifyImportedShippingDiscount,
        types.shopifyGiftCard,
      ].includes(this.type);
    }

    return false;
  }),

  supportsMinPurchase: computed('type', function () {
    return (
      this.isBigcommerceType &&
      // TODO #rewards-reboot here just making sure we do same thing as previous implementation
      // check whether there are any reasons we are doing this
      ![
        types.bigcommerceFreeProduct,
        types.bigcommerceFreeShippingCoupon,
        types.bigcommerceStoreCredit,
      ].includes(this.type)
    );
  }),

  supportsValue: computed('type', function () {
    return ![
      types.bigcommerceFreeShippingCoupon,
      types.shopifyPriceRuleFreeShippingDiscount,
      types.shopifyImportedShippingDiscount,
      types.email,
    ].includes(this.type);
  }),

  supportsMaximumShippingAmount: computed('type', function () {
    return [
      types.shopifyPriceRuleFreeShippingDiscount,
      types.shopifyImportedShippingDiscount,
    ].includes(this.type);
  }),

  hasPercentageValue: computed('type', function () {
    return [
      types.bigcommercePercentageDiscountCoupon,
      types.shopifyPriceRulePercentageDiscount,
      types.shopifyPosPercentageDiscount,
      types.shopifyImportedPercentageDiscount,
      types.rechargeSubscriptionPercentageDiscount,
    ].includes(this.type);
  }),

  isImportable: computed('type', function () {
    return [
      types.customImportedCoupon,
      types.email,
      types.shopifyImportedFixedAmountDiscount,
      types.shopifyImportedFreeProduct,
      types.shopifyImportedPercentageDiscount,
      types.shopifyImportedShippingDiscount,
    ].includes(this.type);
  }),

  removeImage: memberAction({
    path: 'remove_image',
    after: serializeAndPush,
  }),
  removeAllConsumableCodes: memberAction({
    path: 'destroy_consumable_reward_codes',
    after: serializeAndPush,
  }),
  startConsumableCodesImport: memberAction({
    path: 'start_consumable_codes_import',
    type: 'post',
    after: serializeAndPush,
  }),
});
