import Model, { hasMany, belongsTo, attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { equal, or, readOnly, bool } from '@ember/object/computed';
import { memberAction, collectionAction } from 'ember-api-actions';
import { serializeAndPush } from 'smile-admin/utils/models/serialize-and-push';

export const states = {
  active: 'active',
  archived: 'archived',
  cancelled: 'cancelled',
  declined: 'declined',
  offered: 'offered',
  pastDue: 'past_due',
  pending: 'pending',
  revoked: 'revoked',
};

export const types = {
  free: 'free',
  stripe: 'stripe',
  shopify: 'shopify',
  manual: 'manual',
};

export default Model.extend({
  type: attr('string'),
  state: attr('string'),
  cancelledReason: attr('string'),
  isDelinquent: attr('boolean'),

  shopifyRecurringApplicationChargeConfirmationUrl: attr('string'),

  trialEndsAt: attr('date'),
  activatedAt: attr('date'),
  cancelledAt: attr('date'),
  nextBilledAt: attr('utc'),
  createdAt: attr('date'),
  updatedAt: attr('date'),

  account: belongsTo('account', {
    async: false,
    inverse: 'billingSubscriptions',
  }),
  billingPaymentMethod: belongsTo('billing-payment-method', {
    async: false,
    inverse: 'billingSubscription',
  }),
  activeBillingDiscount: belongsTo('billing-discount', {
    async: false,
    inverse: null,
  }),
  pendingBillingDiscount: belongsTo('billing-discount', {
    async: false,
    inverse: null,
  }),

  billingSubscriptionItems: hasMany('billing-subscription-item', {
    async: false,
    inverse: null,
  }),
  billingSubscriptionDefaults: hasMany('billing-subscription-default', {
    async: false,
    inverse: null,
  }),

  isActive: equal('state', states.active).readOnly(),
  isArchived: equal('state', states.archived).readOnly(),
  isCancelled: equal('state', states.cancelled).readOnly(),
  isDeclined: equal('state', states.declined).readOnly(),
  isOffered: equal('state', states.offered).readOnly(),
  isPastDue: equal('state', states.pastDue).readOnly(),
  isPending: equal('state', states.pending).readOnly(),
  isChargeable: or('isActive', 'isPastDue').readOnly(),

  isFreeType: equal('type', types.free).readOnly(),
  isStripeType: equal('type', types.stripe).readOnly(),
  isShopifyType: equal('type', types.shopify).readOnly(),
  isManualType: equal('type', types.manual).readOnly(),

  isLegacyGrowth: readOnly('basePlan.billingProduct.isLegacyGrowth'),
  isLegacy: readOnly('basePlan.billingProduct.isLegacy'),

  isFree: readOnly('basePlan.billingProduct.isFree'),
  isStarter: readOnly('basePlan.billingProduct.isStarter'),
  isGrowth: readOnly('basePlan.billingProduct.isGrowth'),
  isPro: readOnly('basePlan.billingProduct.isPro'),
  isStarterWithOrderLimit: readOnly(
    'basePlan.billingProduct.isStarterWithOrderLimit'
  ),
  isGrowthWithOrderLimit: readOnly(
    'basePlan.billingProduct.isGrowthWithOrderLimit'
  ),
  isPlus: readOnly('basePlan.billingProduct.isPlus'),
  isEnterprise: readOnly('basePlan.billingProduct.isEnterprise'),

  hasPaymentMethod: bool('billingPaymentMethod').readOnly(),

  basePlan: readOnly('baseSubscriptionDefault.newBillingPlan'),
  basePlanName: readOnly('basePlan.billingProduct.name'),
  basePlanSlug: readOnly('basePlan.billingProduct.slug'),
  friendlyBasePlanName: readOnly('basePlan.billingProduct.displayName'),

  membersAddOnNewBillingPlan: readOnly(
    'membersAddOnSubscriptionDefault.newBillingPlan'
  ),
  ordersAddOnNewBillingPlan: readOnly(
    'ordersAddOnSubscriptionDefault.newBillingPlan'
  ),
  appsAddOnNewBillingPlan: readOnly(
    'appsAddOnSubscriptionDefault.newBillingPlan'
  ),

  basePlanUsageAllowance: readOnly('basePlan.billingPlanUsageAllowance'),

  /**
   * Returns true if it's a grandfathered pricing plan: not free-type, legacy, non-enterprise
   * (legacy Basic, Professional, Small Business)
   */
  isLegacyGrandfathered: computed(
    '{isLegacy,isFreeType}',
    'isLegacyEnterprise',
    function () {
      // If it's not a legacy subscription, obv not a grandfathered one
      if (!this.isLegacy) {
        return false;
      }

      if (this.isFreeType) {
        return false;
      }

      // Legacy enterprise plans are also not considered grandfathered plans
      return !this.isLegacyEnterprise;
    }
  ).readOnly(),

  isLegacyFree: computed('basePlan.billingProduct.slug', function () {
    return (
      this.get('basePlan.billingProduct.slug').indexOf('legacy') !== -1 &&
      this.get('basePlan.billingProduct.slug').indexOf('free') !== -1
    );
  }).readOnly(),

  isLegacyEnterprise: computed('basePlan.billingProduct.slug', function () {
    return (
      this.get('basePlan.billingProduct.slug').indexOf('legacy') !== -1 &&
      this.get('basePlan.billingProduct.slug').indexOf('enterprise') !== -1
    );
  }).readOnly(),

  isLegacySmallBusiness: computed('basePlan.billingProduct.slug', function () {
    return (
      this.get('basePlan.billingProduct.slug').indexOf(
        'legacy-smallbusiness'
      ) !== -1
    );
  }).readOnly(),

  isLegacyBasic: computed('basePlan.billingProduct.slug', function () {
    return (
      this.get('basePlan.billingProduct.slug').indexOf('legacy-basic') !== -1
    );
  }).readOnly(),

  baseSubscriptionItem: computed(
    'billingSubscriptionItems.@each.isBaseItem',
    function () {
      return this.get('billingSubscriptionItems').find(
        (item) => item.isBaseItem
      );
    }
  ).readOnly(),

  membersAddOnSubscriptionItem: computed(
    'billingSubscriptionItems.@each.isMembersAddOnItem',
    function () {
      return this.get('billingSubscriptionItems').find(
        (item) => item.isMembersAddOnItem
      );
    }
  ).readOnly(),

  ordersAddOnSubscriptionItem: computed(
    'billingSubscriptionItems.@each.isOrdersAddOnItem',
    function () {
      return this.get('billingSubscriptionItems').find(
        (item) => item.isOrdersAddOnItem
      );
    }
  ).readOnly(),

  appsAddOnSubscriptionItem: computed(
    'billingSubscriptionItems.@each.isAppsAddOnItem',
    function () {
      return this.get('billingSubscriptionItems').find(
        (item) => item.isAppsAddOnItem
      );
    }
  ).readOnly(),

  baseSubscriptionDefault: computed(
    'billingSubscriptionDefaults.@each.isBaseDefault',
    function () {
      return this.get('billingSubscriptionDefaults').find(
        (item) => item.isBaseDefault
      );
    }
  ).readOnly(),

  membersAddOnSubscriptionDefault: computed(
    'billingSubscriptionDefaults.@each.isMembersAddOnDefault',
    function () {
      return this.get('billingSubscriptionDefaults').find(
        (item) => item.isMembersAddOnDefault
      );
    }
  ).readOnly(),

  ordersAddOnSubscriptionDefault: computed(
    'billingSubscriptionDefaults.@each.isOrdersAddOnDefault',
    function () {
      return this.get('billingSubscriptionDefaults').find(
        (item) => item.isOrdersAddOnDefault
      );
    }
  ).readOnly(),

  appsAddOnSubscriptionDefault: computed(
    'billingSubscriptionDefaults.@each.isAppsAddOnDefault',
    function () {
      return this.get('billingSubscriptionDefaults').find(
        (item) => item.isAppsAddOnDefault
      );
    }
  ).readOnly(),

  activate: memberAction({
    path: 'activate',
    type: 'post',
    after: serializeAndPush,
  }),
  // TODO ember-api-actions: is this used anywhere?
  cancel: memberAction({
    path: 'cancel',
    type: 'put',
    after: serializeAndPush,
  }),
  declineOffer: memberAction({
    path: 'decline_offer',
    type: 'put',
    after: serializeAndPush,
  }),
  createShopifyCharge: memberAction({
    path: 'create_shopify_charge',
    type: 'post',
    after: serializeAndPush,
  }),

  shopifyPaymentsCallback: collectionAction({
    path: 'shopify_payments_cb',
    type: 'put',
    after: serializeAndPush,
  }),
});
