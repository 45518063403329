import repeat from 'ember-composable-helpers/helpers/repeat';
import LegacyCard from '@smile-io/ember-shopify-polaris/components/legacy-card';
import SkeletonBodyText from '@smile-io/ember-shopify-polaris/components/skeleton-body-text';
import { or } from 'ember-truth-helpers';

<template>
  {{#each (repeat (or @count 3))}}
    <LegacyCard
      @sectioned={{true}}
      @title={{or @title (component SkeletonBodyText lines=1)}}
      ...attributes
    >
      <SkeletonBodyText @lines={{or @skeletonLines 3}} />
    </LegacyCard>
  {{/each}}
</template>
