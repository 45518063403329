import Component from '@glimmer/component';
import { service } from '@ember/service';
import { on } from '@ember/modifier';

export default class ShopifyEmbeddedNav extends Component {
  @service router;
  @service sesh;
  @service shopify;

  get showShopifyNav() {
    return (
      this.shopify.isEmbedded &&
      !this.sesh.account?.lockedPendingPayment &&
      !this.sesh.account?.shouldSeeLockoutScreen &&
      this.sesh.account?.isQuizComplete
    );
  }

  handleNavigation = (event) => {
    // Prevent default behaviour to avoid a full page reload
    event.preventDefault();
    this.router.replaceWith(event.target.pathname);
  };

  <template>
    {{#if this.showShopifyNav}}
      <ui-nav-menu>
        <a href="/" rel="home" {{on "click" this.handleNavigation}}>Home</a>
        <a href="/reward-programs" {{on "click" this.handleNavigation}}>
          Program
        </a>
        <a href="/customers" {{on "click" this.handleNavigation}}>Customers</a>
        <a href="/analytics" {{on "click" this.handleNavigation}}>Analytics</a>
        <a href="/on-site" {{on "click" this.handleNavigation}}>On-site content</a>
        <a href="/integrations" {{on "click" this.handleNavigation}}>
          Integrations
        </a>
        <a href="/settings" {{on "click" this.handleNavigation}}>Settings</a>
      </ui-nav-menu>
    {{/if}}
  </template>
}
