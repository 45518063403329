import Service, { inject as service } from '@ember/service';
import { tracked, cached } from '@glimmer/tracking';
import { storageFor } from 'ember-local-storage';
import { loadScript } from 'smile-admin/utils/load-script';
import { datadogLogs } from '@datadog/browser-logs';
import config from 'smile-admin/config/environment';

const oauthParamKeys = ['host', 'hmac', 'shop', 'timestamp', 'embedded'];

export default class ShopifyService extends Service {
  @service errorHandler;
  @service router;
  @service sesh;

  @storageFor('sweet-tooth-session') localStorage;

  @tracked appBridge;
  @tracked lastTransition;

  @cached
  get isEmbedded() {
    return hasShopifyOauthParams() && isEmbedded();
  }

  get host() {
    return this.appBridge?.config?.host;
  }

  /**
   * Part of experiment to improve LCP on the home page by skipping API calls and show somethings static instead.
   *  https://smileio.atlassian.net/browse/AS-409
   */
  get showStaticCardsOnHomePage() {
    return (
      this.lastTransition?.to?.name === 'home' &&
      this.isEmbedded &&
      this.sesh.isOnboardingComplete &&
      !this.localStorage.get('lockedPendingPayment') &&
      !this.localStorage.get('shouldSeeLockoutScreen')
    );
  }

  async loadShopifyAppBridge() {
    const metaTags = [
      {
        name: 'shopify-api-key',
        content: config.shopify.clientId,
      },
      // Disable some features:
      // auto-redirect - by default, when the Shopify AppBridge CDN script
      //                 loads, it will automatically redirect to the app
      //                 embedded inside Shopify. This behavior is not built for
      //                 single-page applications like ours and causes the
      //                 install process to break (we instead want to hit the
      //                 API to perform oauth in this case). While __right now__
      //                 for us it doesn't matter because we dynamically load
      //                 the AppBridge script, adding it explicitly here so it
      //                 doesn't bite us in the ass in the future.
      // fetch -  App Bridge injects automatic authorization into the global
      //          fetch function. We don't need this right now, though we might
      //          start using it in the future with the new Ember Data API.
      //          Source:
      //          https://shopify.dev/docs/api/app-bridge-library/apis/resource-fetching
      { name: 'shopify-disabled-features', content: 'auto-redirect,fetch' },
    ];

    if (config.measureWebVitals) {
      metaTags.push({ name: 'shopify-debug', content: 'web-vitals' });
    }
    injectMetaTags(metaTags);

    await loadScript('https://cdn.shopify.com/shopifycloud/app-bridge.js', {
      async: false,
    });
  }

  async setup() {
    if (this._setupDone) {
      return;
    }

    this._setupDone = true;

    await this.loadShopifyAppBridge();
    this.appBridge = window.shopify;

    this.logWebVitalsToDatadog();

    this.router.on('routeWillChange', (transition) => {
      // We keep track of the last transition to use in `showStaticCardsOnHomePage` getter
      this.lastTransition = transition;
    });
  }

  logWebVitalsToDatadog() {
    // It seems Shopify POS doesn't have the `webVitals` API for us to hook into
    if (this.appBridge.environment.pos) {
      return;
    }

    const logWebVitalsToDatadog = async (metrics) =>
      metrics.metrics.forEach((metric) =>
        datadogLogs.logger.info(metric.name, metric)
      );

    this.appBridge.webVitals.onReport(logWebVitalsToDatadog);
  }

  async fetchSessionToken() {
    if (!this.isEmbedded) {
      throw new Error(
        'Shopify session tokens are available only when embedded in Shopify!'
      );
    }

    if (!this.appBridge) {
      await this.setup();
    }

    return this.appBridge.idToken();
  }
}

const hasShopifyOauthParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return oauthParamKeys.every(
    (paramKey) => paramKey === 'embedded' || urlSearchParams.has(paramKey)
  );
};

const isEmbedded = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return urlSearchParams.has('embedded');
};

const shouldTriggerOauth = () => {
  return hasShopifyOauthParams() && !isEmbedded();
};

export const attemptShopifyOauth = async (transition) => {
  // Trigger oauth only if needed
  if (!shouldTriggerOauth()) {
    return;
  }

  // Abort current transition
  // We don't want to let the app to continue loading, instead we abort the transition to allow
  // Shopify oauth to happen and show loading indicator in that time.
  transition.abort();
  await triggerOauth();
};

const triggerOauth = async () => {
  const oauthUrlParams = new URLSearchParams(window.location.search).toString();
  const oauthUrl = `${config.publicApiHost}/v1/auth/shopify?${oauthUrlParams}`;

  window.location.assign(oauthUrl);
};

function injectMetaTags(metaTags) {
  metaTags.forEach(({ name, content }) => {
    const meta = document.createElement('meta');
    meta.name = name;
    meta.content = content;
    document.head.appendChild(meta);
  });
}
